import axios from "axios";
import { API_URL } from "@/const";

export default {
  data() {
    return {
      generatorCollectList: [],
      selectGeneratorCollectList: [], //入力テキストで絞る排出事業者選択肢
      selectGeneratorDisposalList: [], //入力テキストで絞る排出事業者選択肢
      selectGenerateStoreList: [], //入力テキストで絞る排出事業場選択肢
      generatorDisposalList: [],
      selectDisposalSiteList: [], //入力テキストで絞る処分場選択肢
      disposalSiteList: [],
      disposalSiteOptions: [], // 処分事業者IDで絞り込まずに、処分場選択肢
      // API返り値（選択肢などに仕様）
      generateStoreOpt: [],
      memberOpt: [],
      memberDisOpt: [],
      jwnetItemsOpt: [],
      recurryingTypesOpt: [],
      routesInfo: [],
      routeDetailInfos: [],
      newRouteDetailInfos: [],
      wasteUnitOpt: [],
      confirmMemberOpt: {},

      // ルート毎
      shapeOpt: [],
      disposalWayTypesOpt: [],
      wasteNameOpt: [],

      harmfulTypes: [],
      prefectures: [],
      // ルート協議書アラート情報取得からの警告メッセージ
      routePriorConsultationAlertMsgs: [
        {
          routeId: 1,
          routePriorconsultationIsLimit: false,
          routePriorconsultationIsAlert: false,
          msg: "",
        }
      ],
      // 取得済み番号利用するか
      isUseAcquiredManifestNo: false,
      generateMemberMetaInfo: {}, //設定した引き渡し担当者情報
    };
  },
  methods: {
    // 運搬事業者選択肢取得API
    getGeneratorCollectApi() {
      this.getGeneratorCollectSelection(API_URL.MANIFEST.GENERATOR_COLLECT_LIST).then((data) => {
        this.generatorCollectList = data;
        this.selectGeneratorCollectList = data;
      }).catch(() => {})
    },
    // 処分事業者選択肢取得API
    getGeneratorDisposalApi() {
      this.getGeneratorDisposalSelection().then((data) => {
        this.generatorDisposalList = data;
        this.selectGeneratorDisposalList = data;
      }).catch(() => {})
    },

    // 処分事業場取得API
    getDisposalSiteApi() {
      this.getDisposalSiteSelection().then((data) => {
        this.disposalSiteList = data
        this.selectDisposalSiteList = data;
        this.disposalSiteOptions = data;
      }).catch(() => {})
    },

    //事業場選択肢取得API    API_URL.SCHEDULE.GENERATE_STORE
    getGenerateStoreOptApi() {
      return axios
        .get(API_URL.SCHEDULE.GENERATE_STORE)
        .then((res) => {
          res.data.generateCompanyList.forEach((company) => {
            company.generateStoreList.forEach((store) => {
              this.generateStoreOpt.push({
                id: store.id,
                name: `${company.generateCompanyName} - ${store.name}`
              });
              this.selectGenerateStoreList.push({
                id: store.id,
                name: `${company.generateCompanyName} - ${store.name}`
              });
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 担当者一覧API    API_URL.MANIFEST.MEMBER_OPT
    // 担当者一覧API（排出）    API_URL.MANIFEST.MEMBER_OPT
    getMemberOptApi(generateStoreId) {
      return axios
        .get(API_URL.MANIFEST.MEMBER_OPT + generateStoreId)
        .then((res) => {
          this.memberOpt = res.data.MemberOptions;
          this.memberDisOpt = res.data.MemberOptions;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 委託契約に紐づく廃棄物種類選択肢取得API    API_URL.ENTRUST.JWNET_ITEM_TYPE
    getEntrustJwnetItemsApi(generateStoreId) {
      axios
        .get(API_URL.ENTRUST.JWNET_ITEM_TYPE + `?generateStoreId=${generateStoreId}`)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 繰り返し情報選択肢取得API     API_URL.DOMAIN.RECURRING
    getRecurryingApi() {
      axios
        .get(API_URL.DOMAIN.RECURRING)
        .then((res) => {
          this.recurryingTypesOpt = res.data.recurringTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート選択肢検索Urlの作成
    getRouteListSearchApiUrl() {

      const params = new URLSearchParams();
      if (this.routeModalSearchParam.jwnetItemTypeId) params.append("jwnetItemTypeId", this.routeModalSearchParam.jwnetItemTypeId);
      if (this.routeModalSearchParam.routeName) params.append("routeName", this.routeModalSearchParam.routeName);
      if (this.routeModalSearchParam.wasteName) params.append("wasteName", this.routeModalSearchParam.wasteName);
      if (this.routeModalSearchParam.collectCompanyId) params.append("collectCompanyId", this.routeModalSearchParam.collectCompanyId);
      if (this.routeModalSearchParam.disposalCompanyId) params.append("disposalCompanyId", this.routeModalSearchParam.disposalCompanyId);
      if (this.routeModalSearchParam.disposalSiteId) params.append("disposalSiteId", this.routeModalSearchParam.disposalSiteId);

      if (this.routeModalSearchParam.wasteTypeIdList) params.append("wasteTypeIdList", this.routeModalSearchParam.wasteTypeIdList);
      if (this.routeModalSearchParam.industrialWasteJwnetTypeIdList) params.append("industrialWasteJwnetTypeIdList", this.routeModalSearchParam.industrialWasteJwnetTypeIdList);
      if (this.routeModalSearchParam.manifestTypeIdList) params.append("manifestTypeIdList", this.routeModalSearchParam.manifestTypeIdList);
      if (this.routeModalSearchParam.isFavorite) params.append("isFavorite", this.routeModalSearchParam.isFavorite);

      return API_URL.ROUTE.LIST+ this.formValue.generateStoreId + '?' + params.toString();
    },

    // ルート選択肢取API      API_URL.ROUTE.LIST
    getRouteListSearchApi() {
      return axios
        .get(this.getRouteListSearchApiUrl())
        .then((res) => {
          this.routesInfo = res.data.routeList;
          this.narrowedRoutesList = [...this.routesInfo];
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 複数ルート情報API
    getRoutesInfoApi(routeIds) {
      const promise = new Promise((resolve, reject) => {
        Promise.all(routeIds.map(async (routeId) => {
          const res = await axios.get(API_URL.ROUTE.DETAIL + routeId)
          if (!res || !res.data) reject(new Error(routeId +': データが見つかりません'));
          this.newRouteDetailInfos.push(res.data);

        })).then(() => {
          // eslint-disable-line no-unused-vars
          const routesListOrder = this.narrowedRoutesList.map(list => list.routeId);
          const sortedSelectdRoutes = this.newRouteDetailInfos.sort((routeA, routeB) => {
            return routesListOrder.indexOf(routeA.routeId) - routesListOrder.indexOf(routeB.routeId);
          });

          const sumRoutes = [...this.routeDetailInfos, ...sortedSelectdRoutes];
          const removedDupRoutes = sumRoutes.filter((route, index, self) => {
            return self.findIndex(_route => _route.routeId === route.routeId) === index
          });
          this.routeDetailInfos = removedDupRoutes;

          resolve('success');

        }).catch((err) => { reject(err); });

      }).catch((err) => {
        this.routeDetailInfos = [];
        this.errMsgsRoute.push(err)
        console.log('複数ルート取得: ', err);
      });

      return promise;
    },

    // 廃棄物数量単位選択肢取得API    API_URL.DOMAIN.WASTE_UNIT
    getWasteUnitApi() {
      axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitOpt = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * 複数数量確定者選択肢取得API
     *
     * {[routeId]: [], ...}
     */
    getConfirmMemberApi(routeIds) {
      const promise = new Promise((resolve, reject) => {
        const confirmMemberObj = {};
        Promise.all(routeIds.map(async (routeId) => {
          const res = await axios.get(`${API_URL.MANIFEST.CONFIRM_MEMBER}${routeId}`);
          if (!res || !res.data) reject(new Error(routeId +': 数量確定者選択肢取得に失敗しました'));
          confirmMemberObj[routeId] = res.data.quantityConfirmMembers;
        })).then(() => {
          this.confirmMemberOpt = confirmMemberObj;
          resolve('success');
        }).catch((err) => {
          reject(err);
        });
      }).catch((err) => {
        this.errMsgsRoute.push(err)
        console.log('複数数量確定者選択肢取得API: ', err);
      });

      return promise;
    },

    /**
     * 複数ルート荷姿選択肢取得API
     *
     * [{routeId: 1, shapes: [{"id": 1, "name": "バラ"}]}]
     */
    getShapesApi(routeIds) {
      const promise = new Promise((resolve, reject) => {
        this.shapeOpt = [];
        Promise.all(routeIds.map(async (routeId) => {
          const res = await axios.get(API_URL.ROUTE.ROUTE_SHAPE_TYPE + routeId);
          if (!res || !res.data) reject(new Error(routeId +': 荷姿選択肢取得に失敗しました'));
          this.shapeOpt.push({
            routeId,
            shapes: res.data.shapeTypeList,
          });
        })).then(() => {
          resolve('success');
        }).catch((err) => {
          reject(err);
        });
      }).catch((err) => {
        this.errMsgsRoute.push(err)
        console.log('複数ルート荷姿選択肢取得API: ', err);
      });

      return promise;

    },

    /**
     * 複数ルート処分方法選択肢取得API
     *
     * [{routeId: 1, disposalWayTypes: [{"id": 100, "name": "再生"}]}]
     */
    getDisposalWayTypesApi(routeIds) {
      const promise = new Promise((resolve, reject) => {
        this.disposalWayTypesOpt = [];
        Promise.all(routeIds.map(async (routeId) => {
          const res = await axios.get(API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE + routeId);
          if (!res || !res.data) reject(new Error(routeId +': 処分方法選択肢取得に失敗しました'));
          this.disposalWayTypesOpt.push({
            routeId,
            disposalWayTypes: res.data.disposalWayTypeList,
          });
        })).then(() => {
          resolve('success');
        }).catch((err) => {
          reject(err);
        });
      }).catch((err) => {
        this.errMsgsRoute.push(err)
        console.log('複数処分方法選択肢取得API: ', err);
      });

      return promise;

    },

    /**
     * 複数CBA品目別廃棄物名称選択肢取得API    API_URL.DOMAIN.WASTE_NAME_CBA_ITEM
     *
     * [{routeId: 1, wasteName: [{"id": 38, "name": "アルミナ粉"}]
     */
    getWasteNameCBAItemApi(routes) {
        const promise = new Promise((resolve, reject) => {
          this.wasteNameOpt = [];
          Promise.all(routes.map(async (route) => {
            const res = await axios.get(API_URL.DOMAIN.WASTE_NAME_CBA_ITEM(route.cbaItemInfo.id))
            if (!res || !res.data) reject(new Error(route.routeId +': CBA品目別廃棄物名称取得に失敗しました'));
            this.wasteNameOpt.push({
              routeId: route.routeId,
              wasteName: res.data.wasteNameList,
            });
          })).then(() => {
            resolve('success');
          }).catch((err) => {
            reject(err);
          });
        }).catch((err) => {
          this.errMsgsRoute.push(err)
          console.log('複数CBA品目別廃棄物名称取得API: ', err);
        });

        return promise;
    },

    // 有害物質名選択肢取得API    API_URL.DOMAIN.HARMFUL_TYPE
    getharmfulApi() {
      axios
        .get(API_URL.DOMAIN.HARMFUL_TYPE)
        .then((res) => {
          this.harmfulTypes = res.data.harmfulTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 複数ルートに紐づく事前協議書のアラート情報取得API
    getRoutePriorconsultationAlert() {
      const routeIds = this.formValue.manifestInfo.routeIds;
      const collectionDate = this.formValue.collectionDate;
      if (routeIds.length < 1) return;

      const promise = new Promise((resolve, reject) => {
        this.routePriorConsultationAlertMsgs = [];
        Promise.all(routeIds.map(async (routeId) => {

          const params = new URLSearchParams();
          params.append('routeId', routeId);
          if (collectionDate) params.append('collectionDate', collectionDate);

          const res = await axios.get(`${API_URL.ROUTE.ROUTE_PRIORCONSULTATION_ALERT}` + '?' + params.toString());
          if (!res || !res.data) reject(new Error(routeId +': 事前協議書のアラート情報取得に失敗しました'));

          if (res.data.isLimit) {
            this.routePriorConsultationAlertMsgs.push({
              routeId,
              routePriorconsultationIsLimit: true,
              routePriorconsultationIsAlert: false,
              msg: '事前協議書の上限値を超過しています。',
            });
          } else if (res.data.isAlert) {
            this.routePriorConsultationAlertMsgs.push({
              routeId,
              routePriorconsultationIsLimit: false,
              routePriorconsultationIsAlert: true,
              msg: '事前協議書の警告基準値に到達しました。',
            });
          }
        })).then(() => {
          resolve('success');
        }).catch((err) => {
          reject(err);
        });
      }).catch((err) => {
        this.errMsgsRoute.push(err);
        console.log('事前協議書のアラート情報取得API: ', err);
      });

      return promise;
    },

    // 番号取得方法取得API
    getManifestNoGetWayApi() {
      return axios
        .get(API_URL.MANIFEST.MANIFEST_NO_GET_WAY)
        .then((res) => {
          this.isUseAcquiredManifestNo = res.data.isUseAcquiredManifestNo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // マニフェスト引き渡し担当者メンバーの拡張情報取得API
    getGenerateMemberGetWayApi(generateStoreId) {
      return axios
        .get(API_URL.MANIFEST.GENERATE_MEMBER_GET_WAY + generateStoreId)
        .then((res) => {
          this.generateMemberMetaInfo = res.data.generateMemberMetaInfo;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
